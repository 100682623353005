<template>
  <div>
    <v-card class="pt-10">
      <v-card-title class="justify-center flex-column">
        <span class="mb-5">{{ instance.user.name }}</span>
        <div
          class="profile-img-card px-3 py-3 mb-5"
          @click="handleFileImport"
        >
          <div>
            <v-avatar
              v-if="instance.thumbnail"
              rounded
              size="150"
            >
              <v-img :src="instance.thumbnail"></v-img>
            </v-avatar>

            <v-avatar
              v-else
              rounded
              size="150"
            >
              <v-img src="@/assets/images/avatars/1.png"></v-img>
            </v-avatar>
          </div>

          <div class="select-file text-center py-1 mt-2 px-2">
            Select Files
          </div>
        </div>
      </v-card-title>

      <v-card-text>
        <h2 class="text-xl mb-2 font-weight-medium">
          Details
        </h2>

        <v-divider></v-divider>

        <v-list>
          <v-list-item
            class="px-0 mb-n2"
            dense
          >
            <span class="font-weight-medium me-2">Username:</span>
            <span class="text-secondary">{{ instance.user.username }}</span>
          </v-list-item>

          <v-list-item
            class="px-0 mb-n2"
            dense
          >
            <span class="font-weight-medium me-2">Role:</span>
            <span class="text-secondary">{{ instance.user.role }}</span>
          </v-list-item>

          <v-list-item
            v-if="instance.company_types_uuid.type === 'sekolah'"
            class="px-0 mb-n2 mt-2"
            dense
          >
            <span
              class="font-weight-medium me-2"
              style="font-size: 20px"
            >Token</span>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-alert
          v-if="instance.company_types_uuid.type === 'sekolah'"
          text
          color="warning"
          class="text-center mt-3 mb-1 "
        >
          <span
            class="font-weight-bold me-2"
            style="font-size: 15px"
          >{{ instance.user.code }}</span>
        </v-alert>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          large
          class="px-16"
          @click="openQRCode"
        >
          CETAK QR CODE
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>
        <h2 class="text-xl mb-2 font-weight-medium">
          Aktivitas
        </h2>
      </v-card-title>

      <v-card-text>
        <v-timeline
          class="timeline-custom-dense timeline-custom-dots mb-4 pb-5"
          dense
        >
          <v-timeline-item
            color="primary"
            small
          >
            <h4 class="font-weight-medium me-1">
              Login
            </h4>
            <p class="mb-0">
              Superadmin login at 10:42 pm
            </p>
          </v-timeline-item>
          <v-timeline-item
            color="success"
            small
          >
            <h4 class="font-weight-medium me-1">
              Login
            </h4>
            <p class="mb-0">
              Superadmin login at 10:42 pm
            </p>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="openQRCodeDialog"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title
          class="headline justify-center"
        ></v-card-title>

        <v-icon
          style="position:absolute;top:20px;right:20px"
          @click="handleCloseQRCodeDialog"
        >
          {{ icons.mdiClose }}
        </v-icon>

        <div
          v-if="!isLoadingQr"
          class="text-center pa-4"
        >
          <h3>
            {{ qrCode.name }}
          </h3>
          <a
            :href="qrCode.link"
            download
            target="_blank"
          >
            <v-img
              :src="qrCode.link"
              :lazy-src="qrCode.link"
              alt="qrcode"
            ></v-img>
          </a>
        </div>

        <div
          v-else
          class="text-center py-5 px- 5"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
            :width="5"
          ></v-progress-circular>
        </div>

        <div
          v-if="!qrCode.link && !isLoadingQr"
          class="text-center pa-4"
        >
          <v-img src="@/assets/images/logos/qr-not-found.svg"></v-img>
        </div>
      </v-card>
    </v-dialog>

    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :width="600"
      :dialog="dialog"
      header="Profil Instansi"
      text-button="Simpan"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <FileInput
            v-model="initialImage"
            label="Gambar"
            outlined
            dense
            :loading-image="loadingImage"
            :disabled="isLoadingData"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="onFileChanged"
          ></FileInput>
        </div>

        <div v-show="showCropper">
          <v-row>
            <v-col cols="12">
              <section class="cropper-area mb-6">
                <div
                  v-if="initialImage === null"
                  class="text-decoration-none"
                >
                  <v-card
                    height="350"
                    color="#efefef"
                    outlined
                    class="d-flex align-center justify-center rounded-lg"
                  >
                    <div class="text-center">
                      <v-img
                        src="@/assets/icons/black/gallery-add.svg"
                        width="70"
                        class="mx-auto my-2"
                      ></v-img>
                      <h3 clas="ma-0">
                        Unggah Gambar
                      </h3>
                    </div>
                  </v-card>
                </div>

                <div
                  v-else
                  class="d-flex justify-start flex-column"
                >
                  <div>
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="1 / 1"
                      :src="imgSrc"
                      preview=".preview"
                      :auto-crop-area="0.9"
                      :min-container-width="250"
                      :min-container-height="180"
                      drag-mode="move"
                      :view-mode="0"
                      :movable="true"
                      :crop-box-resizable="true"
                    />
                  </div>

                  <div class="actions text-center py-2">
                    <v-btn
                      color="primary"
                      outlined
                      small
                      class="mr-3 btn-reset"
                      @click="reset"
                    >
                      <v-icon left>
                        {{ icons.mdiRefresh }}
                      </v-icon>
                      Reset
                    </v-btn>

                    <v-btn
                      color="primary"
                      small
                      class="btn-crop"
                      @click="cropImage"
                    >
                      <v-icon left>
                        {{ icons.mdiCrop }}
                      </v-icon>
                      Crop
                    </v-btn>
                  </div>
                </div>
              </section>
            </v-col>

            <v-col
              v-show="showPreview == true"
              cols="12"
              class="pt-0"
            >
              <section class="preview-area mb-6">
                <p class="mt-0 mb-8">
                  Preview
                </p>
                <div class="cropped-image">
                  <img
                    v-if="cropImg"
                    :src="cropImg"
                    alt="Cropped Image"
                    class="rounded-lg"
                  />
                  <div
                    v-else
                    class="crop-placeholder rounded-lg"
                  />
                </div>
              </section>
            </v-col>
          </v-row>
        </div>
      </template>
    </modaldialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiClose, mdiCrop, mdiImageMultipleOutline, mdiRefresh } from '@mdi/js'
import 'cropperjs/dist/cropper.css'
import VueCropper from 'vue-cropperjs'
import FileInput from '../../../../components/FileInput.vue'
import ModalDialog from '../../../../components/ModalDialog.vue'

export default {
  components: {
    VueCropper,
    ModalDialog,
    FileInput,
  },
  props: {
    instanceUuid: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      instance: {
        user: {},
        company_types_uuid: {
          type: '',
        },
      },
      user: {
        thumbnail: null,
      },
      dialog: '',
      isLoadingButton: false,
      isLoadingData: false,
      loadingImage: false,
      openQRCodeDialog: false,
      modalDialog: false,
      isLoadingQr: false,
      qrCode: {
        name: null,
        link: null,
      },
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
        mdiCrop,
        mdiRefresh,
      },
      serviceInstance: 'instance',
      imgSrc: '',
      cropImg: '',
      imageCropped: false,
      showCropper: false,
      showPreview: false,
    }
  },
  computed: {
    initialImage: {
      get() {
        if (typeof this.user.thumbnail === 'string') {
          return new File([this.user.thumbnail], this.user.thumbnail, { type: 'text/plain' })
        }

        return this.user.thumbnail
      },
      set() {
        return this.user.thumbnail
      },
    },
  },
  watch: {
    instanceUuid: {
      handler() {
        this.resetForm()
        if (this.instanceUuid) {
          this.getInstance()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getInstance()
  },
  methods: {
    handleFileImport() {
      this.dialog = 'edit'
      this.user.thumbnail = null
      this.isLoadingData = false
      this.loadingImage = false
      this.showPreview = false
      this.imageCropped = false
      this.showCropper = false
      this.modalDialog = true
    },

    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.imageCropped = true
      this.showPreview = true
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        this.user.thumbnail = blob
      }, this.mime_type)
    },

    reset() {
      this.$refs.cropper.reset()
      this.showPreview = false
      this.imageCropped = false
    },

    onFileChanged(event) {
      this.imageCropped = false
      this.user.thumbnail = event
      const file = event

      if (file.type.indexOf('image/') === -1) {
        this.showSnackbar({
          text: 'Silahkan tambahkan satu file gambar',
          color: 'error',
        })

        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.imgSrc = event.target.result
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        this.showSnackbar({
          text: 'Maaf, FileReader API tidak didukung',
          color: 'error',
        })
      }

      this.showCropper = true
    },

    openQRCode(uuid) {
      this.isLoadingQr = true
      this.openQRCodeDialog = true
      this.isLoadingQr = false
    },

    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('thumbnail', this.user.thumbnail)

      await this.$services.ApiServices.update(this.serviceInstance, this.formData, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.showSnackbar({
            text: 'Berhasil Mengubah Logo Instansi',
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )

      this.isLoadingButton = false
      this.modalDialog = false
    },

    async getInstance() {
      this.isLoadingData = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.qrCode.link = data.data.qr_code
          this.qrCode.name = data.data.name
          this.isLoadingData = false
        },
        err => {
          console.error(err)
          this.isLoadingData = false
        },
      )
      this.isLoadingData = false
    },

    handleCloseQRCodeDialog() {
      this.openQRCodeDialog = false
    },

    handlerButton() {
      if (this.dialog === 'edit') this.update()
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.custom-chip {
  height: auto !important;
}

.select-file {
  font-size: 12px;
  background-color: #D9D9D9;
  width: max-content;
  margin: auto;
  border-radius: 2px;
  cursor: pointer;
}

.profile-img-card {
  width: 210px;
  height: 210px;
  border: 2px dashed #dadada;
  text-align: center;
  border-radius: 10px;
}

.cropper-container {
  height: 400px;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #ffffff;
  outline: auto !important;
}

.btn-reset,
.btn-crop {
  width: 120px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 300px;
  height: 300px;
  background: #ccc;
}

.cropped-image {
  width: 300px;
  height: 300px;
  margin: 0 auto ;
}

.cropped-image img {
  width: 100%;
}
</style>
