<template>
  <div>
    <v-card>
      <div>
        <v-card-title>
          <h2 class="text-xl mb-2 font-weight-medium">
            Informasi
          </h2>
        </v-card-title>
        <v-card-text class="mt-1">
          <v-row>
            <v-col
              md="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-menu
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-text-field
                      v-model="instance.schedule_open"
                      label="Jadwal Buka"
                      outlined
                      readonly
                      v-bind="attrs"
                      hide-details
                      :loading="isLoadingData"
                      :disabled="isLoadingData"
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="instance.schedule_open"
                    class="mt-4"
                    format="24hr"
                  ></v-time-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col
              md="6"
              cols="12"
              class="py-0 mb-8"
            >
              <div>
                <v-menu
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-text-field
                      v-model="instance.schedule_closed"
                      label="Jadwal Tutup"
                      outlined
                      readonly
                      v-bind="attrs"
                      hide-details
                      :loading="isLoadingData"
                      :disabled="isLoadingData"
                      dense
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-time-picker
                    v-model="instance.schedule_closed"
                    class="mt-4"
                    format="24hr"
                  ></v-time-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="py-0"
            >
              <div>
                <v-autocomplete
                  v-model="instance.province_uuid"
                  label="Provinsi"
                  item-text="name"
                  item-value="uuid"
                  dense
                  :items="provinces"
                  :loading="isLoadingData"
                  outlined
                  :disabled="!provinces.length"
                  @change="changeListRegencies()"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="py-0"
            >
              <div>
                <v-autocomplete
                  v-model="instance.regencies_uuid"
                  label="Kabupaten/Kota"
                  item-text="name"
                  item-value="uuid"
                  :items="regenciesByProvince"
                  :loading="loadingRegencies"
                  dense
                  outlined
                  :disabled="!regenciesByProvince.length"
                  @change="changeListDistrict()"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="py-0"
            >
              <div>
                <v-autocomplete
                  v-model="instance.district_uuid"
                  :items="districtsByRegencies"
                  :loading="loadingDistrict"
                  label="Kecamatan"
                  item-text="name"
                  item-value="uuid"
                  dense
                  outlined
                  :disabled="!districtsByRegencies.length"
                  @change="changeListVillage()"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="py-0"
            >
              <div>
                <v-autocomplete
                  v-model="instance.village_uuid"
                  :items="villagesByDistricts"
                  :loading="loadingVillages"
                  label="Desa"
                  item-text="name"
                  item-value="uuid"
                  dense
                  outlined
                  :disabled="!villagesByDistricts.length"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <div>
                <v-textarea
                  v-model="instance.address"
                  label="Alamat"
                  hide-details
                  outlined
                  dense
                  :loading="isLoadingData"
                  :disabled="isLoadingData"
                ></v-textarea>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div>
        <v-card-title>
          <h2 class="text-xl mb-2 font-weight-medium">
            Lokasi
          </h2>
        </v-card-title>
        <v-card-text class="mt-1">
          <v-row>
            <v-col
              md="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="instance.latitude"
                  label="Latitude"
                  outlined
                  :loading="isLoadingData"
                  :disabled="isLoadingData"
                  dense
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              md="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="instance.longitude"
                  label="Longitude"
                  outlined
                  :loading="isLoadingData"
                  :disabled="isLoadingData"
                  dense
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <div>
                <iframe
                  :src="url"
                  width="100%"
                  height="300"
                  style="border: 0"
                  allowfullscreen
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center mt-5">
          <div class="">
            <v-btn
              color="primary"
              :loading="isLoadingButton"
              :disabled="isLoadingButton"
              @click="updateProfile"
            >
              SIMPAN
            </v-btn>
          </div>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiArrowLeft,
  mdiCloseCircleOutline,
  mdiFlaskEmptyPlusOutline,
  mdiImageMultipleOutline,
  mdiNoteOutline,
  mdiPlus,
} from '@mdi/js'

export default {
  name: 'UserTabOverview',
  props: {
    instanceUuid: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      icons: {
        mdiNoteOutline,
        mdiFlaskEmptyPlusOutline,
        mdiPlus,
        mdiCloseCircleOutline,
        mdiImageMultipleOutline,
        mdiArrowLeft,
      },
      isLoadingData: false,
      isLoadingButton: false,
      loadingRegencies: false,
      loadingDistrict: false,
      loadingVillages: false,
      instance: {
        schedule_open: '',
        schedule_closed: '',
        address: '',
        longitude: '',
        latitude: '',
        user: {},
        province_uuid: null,
        regencies_uuid: null,
        district_uuid: null,
        village_uuid: null,
      },
      url: '',
      serviceInstance: 'instance',
      provinces: [],
      regenciesByProvince: [],
      districtsByRegencies: [],
      villagesByDistricts: [],
    }
  },
  watch: {
    instanceUuid: {
      async handler() {
        this.resetForm()
        if (this.instanceUuid) {
          await this.getInstance()
          await this.changeListRegencies()
          await this.changeListDistrict()
          await this.changeListVillage()
        }
      },
      deep: true,
    },
  },
  created() {
    this.listProvince()
  },
  async mounted() {
    if (this.instanceUuid) {
      await this.getInstance()
      await this.changeListRegencies()
      await this.changeListDistrict()
      await this.changeListVillage()
    }
  },
  methods: {
    async getInstance() {
      this.isLoadingData = true
      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.instance.province_uuid = data.data.profile?.province?.uuid
          this.instance.regencies_uuid = data.data.profile?.regencies?.uuid
          this.instance.district_uuid = data.data.profile?.district?.uuid
          this.instance.village_uuid = data.data.profile?.village?.uuid
          this.url = `https://maps.google.com/maps?q=${data.data.latitude},${data.data.longitude}&hl=es;z=14&output=embed`

          this.isLoadingData = false
        },
        err => {
          console.error(err)
        },
      )

      this.isLoadingData = false
    },

    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },

    async changeListRegencies() {
      this.loadingRegencies = true
      await this.$services.ApiServices.list('regency', {
        per_page: 'all',
        province_uuid: this.instance.province_uuid,
      }).then(
        ({ data }) => {
          this.regenciesByProvince = data.data
        },
        err => console.error(err),
      )
      this.loadingRegencies = false
    },

    async changeListDistrict() {
      this.loadingDistrict = true
      await this.$services.ApiServices.list('district', {
        per_page: 'all',
        regencies_uuid: this.instance.regencies_uuid,
      }).then(
        ({ data }) => {
          this.districtsByRegencies = data.data
        },
        err => console.error(err),
      )
      this.loadingDistrict = false
    },

    async changeListVillage() {
      this.loadingVillages = true
      await this.$services.ApiServices.list('village', {
        per_page: 'all',
        district_uuid: this.instance.district_uuid,
      }).then(
        ({ data }) => {
          this.villagesByDistricts = data.data
        },
        err => console.error(err),
      )
      this.loadingVillages = false
    },

    async updateProfile() {
      this.isLoadingButton = true
      const data = {
        latitude: this.instance.latitude ? this.instance.latitude : this.$delete(this.instance, 'latitude'),
        longitude: this.instance.longitude ? this.instance.longitude : this.$delete(this.instance, 'longitude'),
        schedule_open: this.instance.schedule_open ? this.instance.schedule_open : this.$delete(this.instance, 'schedule_open'),
        schedule_closed: this.instance.schedule_closed ? this.instance.schedule_closed : this.$delete(this.instance, 'schedule_closed'),
        address: this.instance.address ? this.instance.address : this.$delete(this.instance, 'address'),
        province_uuid: this.instance.province_uuid ? this.instance.province_uuid : this.$delete(this.instance, 'province_uuid'),
        regencies_uuid: this.instance.regencies_uuid ? this.instance.regencies_uuid : this.$delete(this.instance, 'regencies_uuid'),
        district_uuid: this.instance.district_uuid ? this.instance.district_uuid : this.$delete(this.instance, 'district_uuid'),
        village_uuid: this.instance.village_uuid ? this.instance.village_uuid : this.$delete(this.instance, 'village_uuid'),
      }
      await this.$services.ApiServices.update(this.serviceInstance, data, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.instance.province_uuid = data.data.profile?.province?.uuid
          this.instance.regencies_uuid = data.data.profile?.regencies?.uuid
          this.instance.district_uuid = data.data.profile?.district?.uuid
          this.instance.village_uuid = data.data.profile?.village?.uuids
          this.showSnackbar({
            text: 'Berhasil Mengubah Data Instansi',
            color: 'success',
          })
          this.isLoadingButton = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.getInstance()
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>
